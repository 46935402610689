import React from "react"
import QueryString from "query-string"

import { Subscribe } from "unstated"
import RPContainer from "../../store"

import ManageProxyApp from "../../components/ManageProxyApp"
import withLayout from "../../components/layout"
import ProductStore from "../../stores/products"

function ManagePage({ location, isMobile, navigate }) {
  const id = QueryString.parse(location.search).id

  return (
    <Subscribe to={[RPContainer, ProductStore]}>
      {(rpstore, productStore) => (
        <ManageProxyApp
          id={id}
          isMobile={isMobile}
          navigate={navigate}
          allSubscriptions={rpstore.state.allSubscriptions}
          fetchSubscriptions={rpstore.fetchSubscriptions}
          cancelSubscription={rpstore.cancelSubscription}
          cancelMPManagedSubscription={rpstore.cancelMPManagedSubscription}
          rotateIP={rpstore.rotateIP}
          updateAutoRotationInterval={rpstore.updateAutoRotationInterval}
          removeAutoRotationInterval={rpstore.removeAutoRotationInterval}
          manageSubsLink={rpstore.state.manageSubsLink}
          fetchSubsLink={rpstore.fetchSubsLink}
          locations={productStore.state.locations}
          fetchLocations={productStore.fetchLocations}
        />
      )}
    </Subscribe>
  )
}

export default withLayout(ManagePage)
