import styled from "styled-components"

const Button = styled.button.attrs({
  type: "button",
})`
  font-weight: 700;
  font-size: 14px;
  line-height: 1.2;

  padding: 14px 24px;
  background: ${props => (props.primary ? "#6579F3" : "#f3f3f4")};
  color: ${props => (props.primary ? "#fafafa" : "#333")};
  border: none;
  border-radius: 4px;
  cursor: ${props => (props.disabled ? "not-allowed" : "pointer")};
  transition: all 0.35s;

  &:active,
  &:disabled {
    background: #b0b0b0;
    transform: scale(0.95);
  }

  ${props =>
    props.responsive &&
    `@media screen and (max-width: 960px) {
    width: 100%;
  }`}
`

export default Button
