import React, { useRef, useState } from "react"
import styled from "styled-components"
import { without, get } from "lodash"
import io from "socket.io-client"

import authStore from "../../stores/auth"
import { updateSubscriptionIPWhiteList } from "../../utils/CallAPIs"

const STATUS = {
  READY: "Ready",
  PROCESSING: "Processing",
}

const initWhiteList = list => {
  if (list.length <= 0) return []
  return list.map(item => ({
    ip: item,
    status: STATUS.READY,
  }))
}

const IPItem = ({ ip, status, onRemoveIp }) => {
  const statusColor = {
    [STATUS.READY]: "#6ABF83",
    [STATUS.PROCESSING]: "#9BA1AA",
  }[status]

  return (
    <div className="ip-author-row ip-author-row--ip-item">
      <span>{ip}</span>
      <span style={{ color: statusColor }}>{status}</span>
      <span className="ip-delete-button" onClick={onRemoveIp}>
        Delete
      </span>
    </div>
  )
}

const IPAuthorization = ({ ipWhitelist, id, reload }) => {
  const inputRef = useRef()
  const [whitelist, setWhiteList] = useState(() => initWhiteList(ipWhitelist))
  const [status, setStatus] = useState({
    error: null,
    message: "",
  })
  const [submitting, setSubmitStatus] = useState(false)

  let socket

  const triggerToastMessage = ({ error, message, timeout = 3000 }) => {
    setStatus({
      error,
      message,
    })
    setTimeout(() => setStatus({ error: false, message: "" }), timeout)
  }

  const updateStatus = ip => {
    setWhiteList(wl =>
      wl.map(i =>
        i.ip === ip
          ? {
              ip: i.ip,
              status: STATUS.READY,
            }
          : i
      )
    )
  }

  const addNewIPWhiteList = ip =>
    setWhiteList(prevWL => [
      ...prevWL,
      {
        ip,
        status: STATUS.PROCESSING,
      },
    ])

  const removeWhiteList = ip =>
    setWhiteList(prevWL => prevWL.filter(item => item.ip !== ip))

  const listenToSocket = (ip, isRemove = false) => {
    const userID = get(authStore, "state.user.id")
    socket = io.connect(process.env.RP_SOCKET_URL, {
      query: `userID=${userID}`,
    })
    socket.on("message", data => {
      if (data.last_apply_settings_success || data.last_apply_setting) {
        triggerToastMessage({
          message: `${isRemove ? "Remove" : "Add"} Success`,
        })
        setSubmitStatus(false)
        if (!isRemove) {
          updateStatus(ip)
        } else {
          removeWhiteList(ip)
        }
        reload()
      } else {
        triggerToastMessage({
          error: true,
          message: `Fail to ${
            isRemove ? "remove" : "add"
          } this IP, Please try again!`,
        })
        setSubmitStatus(false)
        removeWhiteList(ip)
        reload()
      }
      socket.close()
    })
  }

  const handleAddIPWhiteList = async () => {
    try {
      const currentInputValue = inputRef.current.value
      if (!currentInputValue)
        return triggerToastMessage({
          error: true,
          message: "IP can not be empty",
        })
      if (
        !/^((25[0-5]|(2[0-4]|1\d|[1-9]|)\d)(\.(?!$)|$)){4}$/g.test(
          currentInputValue
        )
      ) {
        return triggerToastMessage({
          error: true,
          message: "IP is not valid",
        })
      }
      setSubmitStatus(true)
      const res = await updateSubscriptionIPWhiteList(id, [
        ...ipWhitelist,
        currentInputValue,
      ])

      inputRef.current.value = ""
      addNewIPWhiteList(currentInputValue)
      listenToSocket(currentInputValue)
      return triggerToastMessage({
        error: false,
        message: res?.data?.message,
        timeout: 20000,
      })
    } catch (error) {
      setSubmitStatus(false)
      triggerToastMessage({
        error: true,
        message: error,
      })
      return error
    }
  }

  const handleRemoveIPWhiteList = async ip => {
    try {
      const res = await updateSubscriptionIPWhiteList(
        id,
        without(ipWhitelist, ip)
      )
      removeWhiteList(ip)
      listenToSocket(ip, true)
      return triggerToastMessage({
        error: false,
        message: res?.data?.message,
        timeout: 20000,
      })
    } catch (error) {
      triggerToastMessage({
        error: true,
        message: error,
      })
      return error
    }
  }

  return (
    <React.Fragment>
      <div>
        <hr />
      </div>
      <h5>IP Authorization</h5>
      <IpAuthorizationInputContainer>
        <label htmlFor="ip-authorization">Add IP address</label>
        <LightInput
          ref={inputRef}
          name="ip-authorization"
          placeholder="IP Address"
        />
        <AddButton onClick={handleAddIPWhiteList} isDisable={submitting}>
          {submitting ? "Adding" : "Add"}
        </AddButton>
      </IpAuthorizationInputContainer>
      {status.message && (
        <ToastMessage error={status.error}>{status.message}</ToastMessage>
      )}
      <IpAuthorizationListContainer>
        <div className="ip-author-row">
          <span>IP address</span>
          <span>Status</span>
          <span>Action</span>
        </div>
        {whitelist.length > 0 ? (
          whitelist.map(wlItem => (
            <IPItem
              {...wlItem}
              onRemoveIp={() => handleRemoveIPWhiteList(wlItem.ip)}
            />
          ))
        ) : (
          <p className="empty-message">The list is empty</p>
        )}
      </IpAuthorizationListContainer>
    </React.Fragment>
  )
}

const LightInput = styled.input`
  margin: 0;
  border-radius: 4px;
  padding: 10px 20px;
  border: 0.25px solid #9ba1aa;
  max-width: 50%;
  &::placeholder {
    color: #9ba1aa;
  }
`

const AddButton = styled.span`
  ${({ isDisable }) =>
    isDisable
      ? `
    pointer-events: none;
    cursor: not-allowed;
  `
      : ""}
`

const IpAuthorizationInputContainer = styled.table`
  display: flex;
  margin: 1.75rem auto 0 auto;
  justify-content: space-evenly;
  align-items: center;
  width: 70%;
  & > * {
    flex: 1;
  }
  & > label {
    font-size: 14px;
    color: #9ba1aa;
  }
  & > span {
    font-size: 14px;
    line-height: 17px;
    color: #6579f3;
    cursor: pointer;
    text-align: right;
  }
`

const IpAuthorizationListContainer = styled.div`
  width: 70%;
  margin: 0 auto;
  .ip-author-row {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    &:first-child {
      border-bottom: 0.25px solid #9ba1aa;
      padding: 30px 0 16px 0;
      margin-bottom: 14px;
    }
    & > * {
      flex: 1;
      font-size: 14px;
      color: #9ba1aa;
    }
    span:not(:first-child) {
      text-align: right;
    }
    &--ip-item {
      padding-bottom: 16px;
      &:last-child {
        padding-bottom: 0;
      }
    }
    .ip-delete-button {
      font-size: 14px;
      line-height: 17px;
      color: #6579f3;
      cursor: pointer;
    }
  }
  .empty-message {
    font-size: 14px;
    color: #6e6d7a;
    text-align: center;
    padding: 30px 0 10px 0;
  }
`
const ToastMessage = styled.p`
  color: ${({ error }) => (error ? "red" : "#6ABF83")} !important;
  margin-top: 5px !important;
  font-size: 14px;
`

export default IPAuthorization
