import { useState } from "react"

const useToastMessage = () => {
  const [messageState, setMessageState] = useState({
    message: "",
    error: false,
  })

  const setToastMessage = ({ message, error, time = 3000 }) => {
    setMessageState({
      message,
      error,
    })
    return setTimeout(
      () => setMessageState({ message: "", error: false }),
      time
    )
  }

  const clearToastConfig = () =>
    setMessageState({
      message: "",
      error: false,
    })

  return [messageState, setToastMessage, clearToastConfig]
}

export default useToastMessage
