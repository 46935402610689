import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import Modal from "../Modal"
import { MyModalBody } from "../ModalHelpers"

const ModalFooter = styled.div`
  display: flex;
  margin-top: 2rem;
  justify-content: space-evenly;
  .button {
    width: 35%;
    text-align: center;

    box-sizing: border-box;
    border-radius: 5px;

    font-size: 14px;
    line-height: 16px;

    padding: 12px;
    cursor: pointer;
    text-decoration: none;
    &:hover {
      opacity: 0.8;
      text-decoration: none;
    }
  }
  .button--close {
    border: 2px solid #667af4;
    background-color: #fff;
  }
  .button--continue {
    color: #fff;
    background-color: rgb(102, 122, 244);
    border: 1px solid transparent;
  }
`

const ConfirmModal = ({ show, onHide }) => {
  return (
    <Modal show={show} onHide={onHide}>
      <MyModalBody>
        You need to buy a subscription to use this feature.
        <br />
        Press Continue button to navigate to product page.
      </MyModalBody>
      <ModalFooter>
        <Link to="/products" className="button button--continue">
          <span>Continue</span>
        </Link>
        <button className="button button--close" onClick={onHide}>
          Cancel
        </button>
      </ModalFooter>
    </Modal>
  )
}

export default ConfirmModal
